import { DOCUMENT, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicTableComponent, IColumn } from '@shared/ui/dynamic-table';
import { PagedActionsComponent, PagedComponent, PagedLoaderComponent, PagedPageComponent } from '@shared/ui/report';
import { safeLocalStorage } from '@shared/util/code';
import { getMonitorType } from '../../../../core/translations/get-questionnaire-types';
import { LocationResponseModel, QuestionnaireType } from '../../../../core/webapi';
import { SelectedLocations } from '../../../../shared/report-label-retriever';
import { CoverContent, getCoverContent } from '../print';
import { PrintResponseResolver } from './print-response.resolver';

type ResponseView = {
  desc: string;
  year3: number;
  year2: number;
  year1: number;
};

interface ICustom {
  editor: boolean;
  response: boolean;
}

@Component({
  selector: 'pma-report-print-responses',
  styleUrls: ['./print-responses.scss'],
  templateUrl: './print-responses.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [PagedComponent, PagedActionsComponent, PagedLoaderComponent, PagedPageComponent, NgIf, NgFor, DynamicTableComponent],
})
export class PrintResponsesComponent implements OnInit {
  readonly baseURI: string;
  readonly coverContent: CoverContent;
  readonly selectedLocations: SelectedLocations;
  readonly fileName: string;
  readonly columnDefinitionsResponse: Array<IColumn<ResponseView, ICustom>> = [
    {
      name: 'desc',
      text: '',
      widths: [250, 250, 250],
      filterFunc: null,
    },
    {
      name: 'year1',
      text: this.resolver.years[0] + '',
      widths: [120, 120, 120],
      arrowPosition: 'before',
      filterFunc: null,
      sortFunc: null,
      valueFunc: (row) => row.year1 + '',
    },
    {
      name: 'year2',
      text: this.resolver.years[1] + '',
      widths: [120, 120, 120],
      arrowPosition: 'before',
      filterFunc: null,
      sortFunc: null,
      valueFunc: (row) => row.year2 + '',
    },
    {
      name: 'year3',
      text: this.resolver.years[2] + '',
      widths: [120, 120, 120],
      arrowPosition: 'before',
      filterFunc: null,
      sortFunc: null,
      valueFunc: (row) => row.year3 + '',
    },
  ];
  responseData!: ResponseView[];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private resolver: PrintResponseResolver,
  ) {
    this.baseURI = this.document.baseURI;
    const questionnaireType = this.resolver.questionnaireType;
    const monitorType = getMonitorType(questionnaireType);
    this.coverContent = getCoverContent(monitorType, this.document);
    this.selectedLocations = JSON.parse(safeLocalStorage.getItem('mdbos.report.locations') ?? '{}');
    this.fileName = this.getFileName(this.selectedLocations.locations);
  }

  ngOnInit() {
    this.responseData = this.getResponseData();
  }

  getResponseData() {
    const responseData = this.resolver.responseData;
    const questionnaireType = this.resolver.questionnaireType;
    const years = this.resolver.years;
    const r = this.createResponseObj(questionnaireType);
    responseData.filter((x) => x.measurementYear === years[0]).forEach((x) => this.addYear1(questionnaireType, r, x, 'year1'));
    responseData.filter((x) => x.measurementYear === years[1]).forEach((x) => this.addYear1(questionnaireType, r, x, 'year2'));
    responseData.filter((x) => x.measurementYear === years[2]).forEach((x) => this.addYear1(questionnaireType, r, x, 'year3'));
    return [...Object.values(r)];
  }

  private addYear1(questionnaireType: QuestionnaireType, r: Record<string, ResponseView>, x: LocationResponseModel, year: keyof ResponseView) {
    switch (questionnaireType) {
      case QuestionnaireType.BookStartPedagogicalEmployee:
      case QuestionnaireType.BookStartConsultant: {
        (r['1'][year] as unknown as number) = x.consultants;
        (r['2'][year] as unknown as number) = x.teachers;
        break;
      }
      case QuestionnaireType.BookStartLibraryEmployee: {
        (r['1'][year] as unknown as number) = x.bookStartSurveys;
        (r['2'][year] as unknown as number) = x.consultants;
        (r['3'][year] as unknown as number) = x.teachers;
        break;
      }
      default: {
        (r['1'][year] as unknown as number) = x.consultants;
        (r['2'][year] as unknown as number) = x.teachers;
        (r['3'][year] as unknown as number) = x.students;
        break;
      }
    }
  }

  private createResponseObj(questionnaireType: QuestionnaireType): Record<string, ResponseView> {
    const r: Record<string, ResponseView> = {};
    switch (questionnaireType) {
      case QuestionnaireType.PoConsultant:
      case QuestionnaireType.PoTeacher:
      case QuestionnaireType.PoStudent: {
        r['1'] = { desc: 'Consulenten', year1: 0, year2: 0, year3: 0 };
        r['2'] = { desc: 'Leerkrachten', year1: 0, year2: 0, year3: 0 };
        r['3'] = { desc: 'Leerlingen', year1: 0, year2: 0, year3: 0 };
        break;
      }
      case QuestionnaireType.VoConsultant:
      case QuestionnaireType.VoTeacher:
      case QuestionnaireType.VoStudent: {
        r['1'] = { desc: 'Consulenten', year1: 0, year2: 0, year3: 0 };
        r['2'] = { desc: 'Docenten', year1: 0, year2: 0, year3: 0 };
        r['3'] = { desc: 'Leerlingen', year1: 0, year2: 0, year3: 0 };
        break;
      }
      case QuestionnaireType.BookStartPedagogicalEmployee:
      case QuestionnaireType.BookStartConsultant: {
        r['1'] = { desc: 'Voorleescoördinator', year1: 0, year2: 0, year3: 0 };
        r['2'] = { desc: 'Pedagogisch medewerker', year1: 0, year2: 0, year3: 0 };
        break;
      }
      case QuestionnaireType.BookStartLibraryEmployee: {
        r['1'] = { desc: 'BoekStartcoördinatoren', year1: 0, year2: 0, year3: 0 };
        r['2'] = { desc: 'Voorleescoördinator', year1: 0, year2: 0, year3: 0 };
        r['3'] = { desc: 'Pedagogisch medewerker', year1: 0, year2: 0, year3: 0 };
        break;
      }
      default:
        throw new Error(`Invalid QuestionnaireType not found ${questionnaireType}`);
    }
    return r;
  }

  private getFileName(locations: string[]) {
    const suffix = `-${new Date().getFullYear()}`;
    if (locations.length) {
      if (locations.length === 1) {
        return `${locations[0]}${suffix}`;
      } else {
        return `responses-${locations.length}-locaties-${new Date().getFullYear()}${new Date().getMonth() + 1}${new Date().getDate()}`;
      }
    }
    return suffix;
  }
}
