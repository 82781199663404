/* eslint-disable max-lines-per-function */
import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterOutlet } from '@angular/router';
import { DropDownItem, HeaderCustomComponent, HeaderDropDownMenu, SideNav, SidenavComponent, SidenavNavbarBottomComponent } from '@shared/ui/layout';
import { isType } from '@shared/util/code';
import { AppInfoComponent } from '@shared/util/infrastructure';
import { ImpersonateService, ImpersonateUser, LastLoginDetailsComponent, UserService } from '@shared/util/user';
import { environment } from '../../environments/environment';
import { SettingsService } from '../core/settings.service';
import { Monitor } from '../core/webapi';
import { UserRoles } from '../shared/mdbos-user-roles';
import { BreadcrumbsProvider } from './breadcrumbs.service';
import { MonitorProvider } from './library/group-tabs/monitor.service';
import { SchoolLocationService } from './library/registration/school-location.service';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ReportType } from './report-settings/report-settings.component';

@Component({
  selector: 'pma-portal',
  templateUrl: './portal.component.html',
  standalone: true,
  imports: [
    SidenavComponent,
    HeaderCustomComponent,
    LastLoginDetailsComponent,
    NgIf,
    SidenavNavbarBottomComponent,
    AppInfoComponent,
    RouterOutlet,
    AsyncPipe,
  ],
})
export class PortalComponent implements OnInit {
  headerMenu: HeaderDropDownMenu = {
    icon: 'account_circle',
    items: [
      { icon: 'logout', name: 'Uitloggen', routerLink: 'auth/logout' },
      { icon: 'person', name: 'Mijn profiel', click: () => this.showProfile() },
    ],
  };
  sideNav!: SideNav;
  expanded!: boolean;
  fieldWorkIsClosed!: boolean;
  readonly clientVersion = environment.buildNumber;

  constructor(
    private userService: UserService,
    private impersonateService: ImpersonateService,
    private locationService: SchoolLocationService,
    private dialog: MatDialog,
    private settingsService: SettingsService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private destroyRef: DestroyRef,
    public breadcrumbsProvider: BreadcrumbsProvider,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _neededToActiveService: MonitorProvider,
  ) {
    this.expanded = true;
    this.sideNav = this.getSideNav();
  }

  async ngOnInit() {
    this.initSchoolLocations();
    this.fieldWorkIsClosed = await this.settingsService.isFieldWorkIsClosed();
    this.sideNav = this.getSideNav();

    this.impersonateService.impersonate$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((name) => {
      if (this.userService.currentOrImpersonateUser.isImpersonateUser) {
        this.initSchoolLocations();
        this.updateUserMenu(name);
      }
    });
    this.changeDetectorRef.markForCheck();
  }

  private updateUserMenu(user: ImpersonateUser) {
    this.headerMenu.items = this.headerMenu.items.filter((x) => !isType<DropDownItem>(x, 'name') || x.name !== 'Stop impersoneren');
    if (user) {
      this.headerMenu.items.push({
        name: 'Stop impersoneren',
        icon: 'logout',
        click: async () => {
          await this.impersonateService.undoImpersonate();
          this.router.navigateByUrl(`/`);
        },
      });
    }
    this.headerMenu = { ...this.headerMenu };
    this.sideNav = [...this.sideNav];
  }

  private showProfile(): void {
    this.dialog.open(MyProfileComponent, { maxWidth: 700 });
  }

  private initSchoolLocations() {
    if (this.userService.currentOrImpersonateUser.role === UserRoles.LibraryCoordinator) {
      this.locationService.init();
    }
  }

  private getSideNav() {
    return [
      { name: 'Gebruikers', routerLink: '/portal/users', icon: 'person', roles: [UserRoles.Administrator] },
      { name: 'Mail instellingen', routerLink: '/portal/settings', icon: 'settings', roles: [UserRoles.Administrator] },
      { name: 'Instellingen', routerLink: '/portal/administration', icon: 'settings', roles: [UserRoles.Administrator] },
      { name: 'SPSS Import', routerLink: '/portal/spss', icon: 'swap_horiz', roles: [UserRoles.Administrator] },
      { name: 'Rapportagegroepen', routerLink: '/portal/reportgroups', icon: 'storage', roles: [UserRoles.Administrator] },
      { name: 'Rapportage-instellingen', icon: 'settings', roles: [UserRoles.Administrator], children: this.reports() },
      {
        name: 'Bibliotheek',
        routerLink: '/portal/library/bibliotheek',
        icon: 'local_library',
        roles: [UserRoles.LibraryCoordinator, UserRoles.ReadConsultant],
      },
      { name: 'Consulenten', routerLink: '/portal/library/consultants', icon: 'person', roles: [UserRoles.LibraryCoordinator] },
      {
        name: 'Locatiecoördinatoren',
        routerLink: '/portal/library/locationcoordinators',
        icon: 'person',
        roles: [UserRoles.LibraryCoordinator, UserRoles.ReadConsultant],
      },
      { name: 'BoekStart', icon: 'baby_changing_station', children: this.boChildren(), hidden: this.fieldWorkIsClosed },
      { name: 'PO', icon: 'accessibility', children: this.poChildren(), hidden: this.fieldWorkIsClosed },
      { name: 'VO', icon: 'hail', children: this.voChildren(), hidden: this.fieldWorkIsClosed },
      {
        name: `IKC's`,
        routerLink: '/portal/library/ikcs',
        icon: 'escalator_warning',
        roles: [UserRoles.LibraryCoordinator],
        hidden: this.fieldWorkIsClosed,
      },
      {
        name: 'Rapportages',
        routerLink: '/reports',
        icon: 'list_alt',
        roles: [
          UserRoles.LibraryCoordinator,
          UserRoles.EducationManager,
          UserRoles.ReadConsultant,
          UserRoles.Administrator,
          UserRoles.ReportCountryManager,
          UserRoles.Poi,
        ],
      },
      {
        name: 'Faq',
        routerLink: '/portal/library/faq',
        icon: 'help_outline',
        roles: [
          UserRoles.LibraryCoordinator,
          UserRoles.ReadConsultant,
          UserRoles.EducationManager,
          UserRoles.ReportCountryManager,
          UserRoles.Poi,
          UserRoles.Administrator,
        ],
      },
    ];
  }

  private voChildren() {
    return [
      { name: 'Registratie VO', routerLink: '/portal/library/registration/vo', icon: 'school', roles: [UserRoles.LibraryCoordinator] },
      { name: 'Scholen VO', routerLink: '/portal/library/groups/vo', icon: 'email', roles: [UserRoles.LibraryCoordinator, UserRoles.ReadConsultant] },
      {
        name: 'Volgers VO',
        routerLink: '/portal/library/followers/vo',
        icon: 'forward_30',
        roles: [UserRoles.LibraryCoordinator, UserRoles.ReadConsultant],
      },
    ];
  }

  private poChildren() {
    return [
      { name: 'Registratie PO', routerLink: '/portal/library/registration/po', icon: 'school', roles: [UserRoles.LibraryCoordinator] },
      { name: 'Groepen PO', routerLink: '/portal/library/groups/po', icon: 'email', roles: [UserRoles.LibraryCoordinator, UserRoles.ReadConsultant] },
      {
        name: 'Volgers PO',
        routerLink: '/portal/library/followers/po',
        icon: 'forward_30',
        roles: [UserRoles.LibraryCoordinator, UserRoles.ReadConsultant],
      },
    ];
  }

  private boChildren() {
    return [
      { name: 'Vragenlijst', routerLink: '/portal/library/questionnaire/bookstart', icon: 'list_alt', roles: [UserRoles.LibraryCoordinator] },
      { name: 'Registratie', routerLink: '/portal/library/registration/bookstart', icon: 'school', roles: [UserRoles.LibraryCoordinator] },
      {
        name: 'Groepen',
        routerLink: '/portal/library/groups/bookstart',
        icon: 'email',
        roles: [UserRoles.LibraryCoordinator, UserRoles.ReadConsultant],
      },
      {
        name: 'Volgers',
        routerLink: '/portal/library/followers/bookstart',
        icon: 'forward_30',
        roles: [UserRoles.LibraryCoordinator, UserRoles.ReadConsultant],
      },
    ];
  }

  private reports() {
    return [
      { name: 'BoekStart', icon: 'baby_changing_station', roles: [UserRoles.Administrator], children: this.reportBo() },
      { name: 'PO', icon: 'accessibility', roles: [UserRoles.Administrator], children: this.reportPo() },
      { name: 'VO', icon: 'hail', roles: [UserRoles.Administrator], children: this.reportVo() },
      {
        name: 'IKC',
        icon: 'escalator_warning',
        roles: [UserRoles.Administrator],
        routerLink: `/portal/reportsettings/${Monitor.BookStart}/${ReportType.Locatie}/1`,
      },
      { name: 'Rapport-instellingen', routerLink: '/portal/reportsettings/definition', icon: 'settings', roles: [UserRoles.Administrator] },
    ];
  }

  private reportVo() {
    return [
      {
        name: 'Leescirkel',
        routerLink: `/portal/reportsettings/${Monitor.Vmbo}/${ReportType.Locatie}`,
        icon: 'auto_stories',
        roles: [UserRoles.Administrator],
      },
      {
        name: 'Gemeente',
        routerLink: `/portal/reportsettings/${Monitor.Vmbo}/${ReportType.Gemeente}`,
        icon: 'auto_stories',
        roles: [UserRoles.Administrator],
      },
      {
        name: 'Bibliotheek',
        routerLink: `/portal/reportsettings/${Monitor.Vmbo}/${ReportType.Bibliotheek}`,
        icon: 'auto_stories',
        roles: [UserRoles.Administrator],
      },
    ];
  }

  private reportPo() {
    return [
      {
        name: 'Leescirkel',
        routerLink: `/portal/reportsettings/${Monitor.Po}/${ReportType.Locatie}`,
        icon: 'auto_stories',
        roles: [UserRoles.Administrator],
      },
      {
        name: 'Gemeente',
        routerLink: `/portal/reportsettings/${Monitor.Po}/${ReportType.Gemeente}`,
        icon: 'auto_stories',
        roles: [UserRoles.Administrator],
      },
      {
        name: 'Bibliotheek',
        routerLink: `/portal/reportsettings/${Monitor.Po}/${ReportType.Bibliotheek}`,
        icon: 'auto_stories',
        roles: [UserRoles.Administrator],
      },
    ];
  }

  private reportBo() {
    return [
      {
        name: 'Leescirkel',
        routerLink: `/portal/reportsettings/${Monitor.BookStart}/${ReportType.Locatie}`,
        icon: 'auto_stories',
        roles: [UserRoles.Administrator],
      },
      {
        name: 'Gemeente',
        routerLink: `/portal/reportsettings/${Monitor.BookStart}/${ReportType.Gemeente}`,
        icon: 'auto_stories',
        roles: [UserRoles.Administrator],
      },
      {
        name: 'Bibliotheek',
        routerLink: `/portal/reportsettings/${Monitor.BookStart}/${ReportType.Bibliotheek}`,
        icon: 'auto_stories',
        roles: [UserRoles.Administrator],
      },
    ];
  }
}
