import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatCard, MatCardActions, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { QRCodeModule } from 'angularx-qrcode';
import { firstValueFrom } from 'rxjs';
import { translateProjectCode } from '../../../core/translations/project-code-nl';
import { UriService } from '../../../core/uri.service';
import { QuestionnaireProjectClient, QuestionnaireType, StartCodeClient, StartCodeInfo } from '../../../core/webapi';
import { MatMdBosIconDirective } from '../../../shared/mdbos-button.directive';

@Component({
  selector: 'pma-overview-page',
  templateUrl: './overview-page.component.html',
  standalone: true,
  imports: [
    NgIf,
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatCardSubtitle,
    MatCardContent,
    MatIcon,
    QRCodeModule,
    MatLabel,
    MatCardActions,
    MatMdBosIconDirective,
    MatButton,
  ],
})
export class OverviewPageComponent {
  qrLink!: string;
  startCodeInfo!: StartCodeInfo;

  constructor(
    private route: ActivatedRoute,
    private uris: UriService,
    private client: QuestionnaireProjectClient,
    private startCodeClient: StartCodeClient,
  ) {
    const startCode = this.route.snapshot.paramMap.get('id')!;
    const questionnaireCode = this.route.snapshot.url[0].path;
    const type = translateProjectCode(questionnaireCode);
    this.getQRcode(type, startCode);
  }

  async getQRcode(type: QuestionnaireType, startCode: string) {
    const projectGuid = await firstValueFrom(this.client.getProjectGuid(type));
    this.startCodeInfo = await firstValueFrom(this.startCodeClient.getGroupInformation(startCode));
    this.qrLink = `${this.uris.urlResponse}${projectGuid}/login/${startCode}`;
  }

  onStart() {
    window.open(this.qrLink, '_blank')?.focus();
  }
}
