<div class="container">
  <div class="col-12 col-xl-10 offset-xl-1">
    <div class="row mb-3">
      <div class="col d-flex justify-content-around mt-1">
        <img src="./assets/images/logo-boekstart.svg" />
        <img src="./assets/images/logo-dbos.svg" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <mat-card class="d-inline-block ds-section--intro">
          <div class="row">
            <div class="col col-8 d-flex flex-column justify-content-between">
              <mat-card-header>
                <mat-card-title>Meten is weten</mat-card-title>
              </mat-card-header>
              <mat-card-content class="mt-4 mb-1">
                <div class="d-flex flex-column flex-lg-row d-inline-block ds-section--intro">
                  <span class="mat-subtitle-2 pe-3 mb-2 mb-lg-0">
                    Hoe kun je doelgericht aan leesbevordering en informatievaardigheden werken? De Monitor BoekStart in de kinderopvang en de Monitor
                    de Bibliotheek <i>op school</i> brengen de opbrengst van de samenwerking tussen kinderdagopvang, school en bibliotheek in beeld.
                    Door elk jaar de monitor uit te voeren, ontstaat een helder beeld van de veranderingen bij kinderen, leerlingen, pedagogisch
                    medewerkers, leerkrachten, docenten en ouders. De monitorresultaten dienen als basis voor het vaststellen van nieuwe
                    leesbevorderingsdoelen en informatievaardigheden.
                    <div class="mt-2">Lees meer:</div>
                  </span>
                </div>
              </mat-card-content>
              <mat-card-actions>
                <a href="http://pro.debibliotheekopschool.nl/monitor.html" mat-raised-button color="primary" matTooltip="Bekijk de monitor" pmaMdBos>
                  De Bibliotheek <i>op school</i>
                </a>
                <a href="https://www.boekstartpro.nl/monitor.html" mat-raised-button color="primary" matTooltip="Bekijk de monitor" pmaMdBos>
                  BoekStart in de kinderopvang
                </a>
              </mat-card-actions>
            </div>
            <div class="col col-4 d-flex">
              <img src="./assets/images/BoekStart-foto-033-1-scaled.jpg" class="d-block align-self-center img-fluid" style="border-radius: 50%" />
            </div>
          </div>
        </mat-card>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12 col-md-4 mb-3 mb-md-0">
        <mat-card>
          <mat-card-header>
            <mat-card-title>BoekStart</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="mb-3">Inloggen voor BoekStartcoördinatoren<br />&nbsp;</div>
            <div class="text-center">
              <div class="mb-3 mat-subtitle-2">Naar de pagina's:</div>
              <div class="mb-3 text-center">
                <a href="./auth/login" mat-raised-button color="primary" matTooltip="Ga naar beheer BoekStart " class="me-1" pmaMdBos>Beheer </a>
                <a href="./reports/response/1" mat-raised-button color="primary" matTooltip="Ga naar rapportage BoekStart " pmaMdBos>Rapportage </a>
              </div>
              <div class="pb-3">
                <mat-divider></mat-divider>
              </div>
              <div class="mb-3"><span class="mat-subtitle-2">Planning</span> komende meting:</div>
              <button mat-stroked-button (click)="onDownload(portal.BookStart)" matTooltip="Download planning BoekStart ">Download</button>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-12 col-md-4 mb-3 mb-md-0">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Primair onderwijs</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="mb-3">Inloggen voor monitorcoördinatoren en lees- en mediaconsulenten</div>
            <div class="text-center">
              <div class="mb-3 mat-subtitle-2">Naar de pagina's:</div>
              <div class="mb-3 text-center">
                <a href="./auth/login" mat-raised-button color="primary" matTooltip="Ga naar beheer Primair onderwijs" class="me-1" pmaMdBos
                  >Beheer
                </a>
                <a href="./reports/response/2" mat-raised-button color="primary" matTooltip="Ga naar rapportage Primair onderwijs" pmaMdBos>
                  Rapportage
                </a>
              </div>
              <div class="pb-3">
                <mat-divider></mat-divider>
              </div>
              <div class="mb-3"><span class="mat-subtitle-2">Planning</span> komende meting:</div>
              <button mat-stroked-button (click)="onDownload(portal.Po)" matTooltip="Download planning Primair onderwijs">Download</button>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-12 col-md-4 mb-3 mb-md-0">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Voortgezet onderwijs</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="mb-3">Inloggen voor monitorcoördinatoren en lees- en mediaconsulenten</div>
            <div class="text-center">
              <div class="mb-3 mat-subtitle-2">Naar de pagina's:</div>
              <div class="mb-3 text-center">
                <a href="./auth/login" mat-raised-button color="primary" matTooltip="Ga naar beheer Voortgezet onderwijs" class="me-1" pmaMdBos
                  >Beheer
                </a>
                <a href="./reports/response/3" mat-raised-button color="primary" matTooltip="Ga naar rapportage Voortgezet onderwijs" pmaMdBos
                  >Rapportage
                </a>
              </div>
              <div class="pb-3">
                <mat-divider></mat-divider>
              </div>
              <div class="mb-3"><span class="mat-subtitle-2">Planning</span> komende meting:</div>
              <button mat-stroked-button (click)="onDownload(portal.Vmbo)" matTooltip="Download planning Voortgezet onderwijs">Download</button>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12 col-md-4 mb-3 mb-md-0 d-flex align-items-stretch">
        <mat-card>
          <mat-card-header>
            <mat-card-title>BoekStart</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="mb-3"><strong>Aanmelden</strong> van een nieuwe <strong>bibliotheek of monitorcoördinator BoekStart</strong></div>
            <a
              href="./registratie/{{ portal.BookStart }}"
              mat-raised-button
              color="primary"
              matTooltip="Aanmelden nieuwe bibliotheek BoekStart"
              pmaMdBos
              >Meld aan</a
            >
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-12 col-md-4 mb-3 mb-md-0">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Primair onderwijs</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="mb-3">
              <strong>Aanmelden</strong> van een nieuwe <strong>bibliotheek of monitorcoördinator</strong> voor het <strong>Primair onderwijs</strong>
            </div>
            <a
              href="./registratie/{{ portal.Po }}"
              mat-raised-button
              color="primary"
              matTooltip="Aanmelden nieuwe bibliotheek Primair onderwijs"
              pmaMdBos
              >Meld aan</a
            >
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-12 col-md-4 mb-3 mb-md-0">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Voortgezet onderwijs</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="mb-3">
              <strong>Aanmelden</strong> van een nieuwe <strong>bibliotheek of monitorcoördinator</strong> voor het
              <strong>Voortgezet onderwijs</strong>
            </div>
            <a
              href="./registratie/{{ portal.Vmbo }}"
              mat-raised-button
              color="primary"
              matTooltip="Aanmelden nieuwe bibliotheek voortgezet onderwijs"
              pmaMdBos
              >Meld aan</a
            >
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="row d-flex mb-3">
      <div class="col-6">
        <img src="assets/images/logo_StichtingLezen.svg" style="width: 140px; border-width: 0px" />
      </div>
      <div class="col-6 d-flex">
        <img class="ms-auto" src="assets/images/logo-kb.svg" style="width: 320px; border-width: 0px" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <footer class="position-relative">
          <img src="./assets/images/bg-plectrum.svg" class="position-absolute plectrum" />

          <div class="position-relative mb-6">
            <img class="img-fluid" src="assets/images/cq5dam.web.1280.2048.jpeg" />
          </div>
        </footer>
      </div>
    </div>
  </div>
</div>
