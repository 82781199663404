import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatAnchor, MatButton } from '@angular/material/button';
import { MatCard, MatCardActions, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatDivider } from '@angular/material/divider';
import { MatTooltip } from '@angular/material/tooltip';
import { saveFile } from '@shared/util/code';
import { firstValueFrom } from 'rxjs';
import { Monitor } from '../../core/webapi';
import { DocumentClient } from '../../core/webapi/apiClient';
import { MatMdBosIconDirective } from '../../shared/mdbos-button.directive';

@Component({
  selector: 'pma-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatCardContent,
    MatCardActions,
    MatAnchor,
    MatMdBosIconDirective,
    MatTooltip,
    MatDivider,
    MatButton,
  ],
})
export class OverviewComponent {
  readonly portal = Monitor;

  constructor(private document: DocumentClient) {}

  async onDownload(portal: Monitor) {
    const source$ = this.document.getDocument(portal === Monitor.BookStart ? 'BookStart' : portal === Monitor.Vmbo ? 'Vo' : 'Po');
    const file = await firstValueFrom(source$);
    saveFile(file);
  }
}
