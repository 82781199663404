import { Injectable } from '@angular/core';

import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsGuard {
  constructor(private settingsService: SettingsService) {}

  async canActivate(): Promise<boolean> {
    return !(await this.settingsService.isFieldWorkIsClosed());
  }
}
