<lsu-layout-sidenav
  width="auto"
  [headerDropDownMenu]="headerMenu"
  [sideNav]="sideNav"
  (expandedChange)="expanded = $event"
  headerTitle="Rapportages voorgaande metingen"
>
  <lsu-layout-header-custom>
    <lsu-user-last-login-details></lsu-user-last-login-details>
  </lsu-layout-header-custom>
  <router-outlet></router-outlet>
</lsu-layout-sidenav>
