import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { safeLocalStorage } from '@shared/util/code';
import { firstValueFrom } from 'rxjs';
import { getMonitorType } from '../../../../core/translations/get-questionnaire-types';
import { MonitorOpenQuestions, ReportClient } from '../../../../core/webapi';

@Injectable({
  providedIn: 'root',
})

// Important the result of this resolve is used!!! in PrintViewOpenQuestionsComponent
export class PrintViewOpenQuestionsResolver {
  constructor(private reportClient: ReportClient) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<MonitorOpenQuestions[]> {
    const questionnaireType = +route.params['questionnaireType'];
    const filter = JSON.parse(safeLocalStorage.getItem('mdbos.report.locationFilter') ?? '{}').filter;
    return await firstValueFrom(this.reportClient.getOpenAnswers(getMonitorType(questionnaireType), [filter]));
  }
}
