<lsu-layout-sidenav
  [headerTitle]="(breadcrumbsProvider.breadCrumbs$ | async)!"
  [headerDropDownMenu]="headerMenu"
  [sideNav]="sideNav"
  (expandedChange)="expanded = $event"
>
  <lsu-layout-header-custom>
    <lsu-user-last-login-details></lsu-user-last-login-details>
  </lsu-layout-header-custom>
  <lsu-layout-sidenav-navbar-bottom *ngIf="expanded">
    <div class="px-2">
      <lsu-infrastructure-app-info [clientVersion]="clientVersion"></lsu-infrastructure-app-info>
    </div>
  </lsu-layout-sidenav-navbar-bottom>
  <router-outlet></router-outlet>
</lsu-layout-sidenav>
