import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Title } from '@angular/platform-browser';
import { AppInsightsService, UserError } from '@shared/util/infrastructure';
import { firstValueFrom } from 'rxjs';
import { UriService } from '../../core/uri.service';
import { StartCodeClient } from '../../core/webapi';
import { MatMdBosIconDirective } from '../../shared/mdbos-button.directive';

@Component({
  selector: 'pma-page-not-found',
  templateUrl: './page-not-found.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatCardContent,
    FormsModule,
    MatFormField,
    MatInput,
    NgIf,
    MatError,
    MatButton,
    MatMdBosIconDirective,
  ],
})
export class PageNotFoundComponent implements OnInit {
  code: string | null = null;

  constructor(
    private title: Title,
    private client: StartCodeClient,
    private uris: UriService,
  ) {}
  ngOnInit(): void {
    const code = window.location.href.split('/').pop()?.slice(-6) ?? '';
    if (code.length === 6) {
      this.redirect(code);
    }
    this.title.setTitle('Pagina niet gevonden');
    AppInsightsService.trackTraceWarn(`Could not find route ${window.location.href}`);
  }
  async start() {
    this.code = this.code?.trim() ?? '';
    if (this.code.length !== 6) {
      throw new UserError(`Ongeldige startcode: ${this.code}. De startcode moet 6 tekens bevatten`, `Ongeldige startcode`);
    }
    await this.redirect(this.code);
  }

  private async redirect(code: string) {
    const guid = await firstValueFrom(this.client.getUrlLink(code!));
    window.location.href = `${this.uris.urlResponse}${guid}/login/${code}`;
  }
}
