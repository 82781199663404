import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LibraryClient, LibraryModel } from './webapi';

@Injectable({ providedIn: 'root' })
export class LibraryService {
  libraries!: LibraryModel[];

  constructor(private client: LibraryClient) {}

  resolve(): Observable<LibraryModel[]> {
    return this.client.getLibraries().pipe(tap((x) => (this.libraries = x)));
  }
}
