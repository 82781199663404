/* eslint-disable max-lines-per-function */
import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterOutlet } from '@angular/router';
import { HeaderCustomComponent, HeaderDropDownMenu, SideNav, SidenavComponent } from '@shared/ui/layout';
import { ImpersonateService, LastLoginDetailsComponent } from '@shared/util/user';
import Highcharts from 'highcharts/highcharts';
import ExportingModule from 'highcharts/modules/exporting';
import { RoleFilterService } from '../../core/role-filter.service';
import { SettingsService } from '../../core/settings.service';
import { Monitor, QuestionnaireType } from '../../core/webapi';
import { MyProfileComponent } from '../../portal/my-profile/my-profile.component';
import { UserRoles } from '../../shared/mdbos-user-roles';

ExportingModule(Highcharts);

@Component({
  selector: 'pma-report',
  templateUrl: './report.component.html',
  standalone: true,
  imports: [SidenavComponent, HeaderCustomComponent, LastLoginDetailsComponent, RouterOutlet],
})
export class ReportComponent implements OnInit {
  headerMenu: HeaderDropDownMenu = {
    icon: 'account_circle',
    items: [
      { icon: 'logout', name: 'Uitloggen', routerLink: 'auth/logout' },
      { icon: 'person', name: 'Mijn profiel', click: () => this.showProfile() },
    ],
  };
  expanded = true;
  sideNav: SideNav;

  constructor(
    private dialog: MatDialog,
    private reportDefaultFilterService: RoleFilterService,
    private impersonateService: ImpersonateService,
    private router: Router,
    private destroyRef: DestroyRef,
    private settingsService: SettingsService,
  ) {
    this.sideNav = this.getSideNav();
  }

  ngOnInit(): void {
    this.impersonateService.impersonate$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((name) => {
      if (name) {
        this.setMenu();
        this.sideNav = this.getSideNav();
      }
    });
  }

  private showProfile(): void {
    this.dialog.open(MyProfileComponent, { maxWidth: 700 });
  }

  private setMenu() {
    this.headerMenu.items.push({
      name: 'Stop impersoneren',
      icon: 'logout',
      click: async () => {
        await this.impersonateService.undoImpersonate();
        this.router.navigateByUrl(`/portal/administration`);
      },
    });
    this.headerMenu = { ...this.headerMenu };
  }

  private getSideNav() {
    return [
      {
        name: 'BoekStart',
        icon: 'baby_changing_station',
        children: [
          { name: 'Respons', routerLink: `/reports/response/${Monitor.BookStart}`, icon: 'show_chart', roles: ['*'] },
          { name: 'Bibliotheek', routerLink: `/reports/questionnaire/${QuestionnaireType.BookStartLibraryEmployee}`, icon: 'list_alt', roles: ['*'] },
          {
            name: 'Voorleescoördinator',
            routerLink: `/reports/questionnaire/${QuestionnaireType.BookStartConsultant}`,
            icon: 'list_alt',
            roles: ['*'],
          },
          {
            name: 'Pedagogisch medewerker',
            routerLink: `/reports/questionnaire/${QuestionnaireType.BookStartPedagogicalEmployee}`,
            icon: 'list_alt',
            roles: ['*'],
          },
          { name: 'Leescirkelrapportage', routerLink: `/reports/location/${Monitor.BookStart}`, icon: 'auto_stories', roles: ['*'] },
          { name: 'Gemeenterapportage', routerLink: `/reports/municipality/${Monitor.BookStart}`, icon: 'auto_stories', roles: ['*'] },
          { name: 'Bibliotheekrapportage', routerLink: `/reports/library/${Monitor.BookStart}`, icon: 'auto_stories', roles: ['*'] },
          { name: 'Landelijke analyse', routerLink: `/reports/country/${Monitor.BookStart}`, icon: 'auto_stories', roles: ['*'] },
        ],
        hidden: this.reportDefaultFilterService.userPoi && !this.reportDefaultFilterService.userPoi.showBookStart,
      },
      {
        name: 'PO',
        icon: 'accessibility',
        children: [
          { name: 'Respons', routerLink: `/reports/response/${Monitor.Po}`, icon: 'show_chart', roles: ['*'] },
          { name: 'Consulent', routerLink: `/reports/questionnaire/${QuestionnaireType.PoConsultant}`, icon: 'list_alt', roles: ['*'] },
          { name: 'Leerkracht', routerLink: `/reports/questionnaire/${QuestionnaireType.PoTeacher}`, icon: 'list_alt', roles: ['*'] },
          { name: 'Leerling', routerLink: `/reports/questionnaire/${QuestionnaireType.PoStudent}`, icon: 'list_alt', roles: ['*'] },
          { name: 'Leescirkelrapportage', routerLink: `/reports/location/${Monitor.Po}`, icon: 'auto_stories', roles: ['*'] },
          { name: 'Gemeenterapportage', routerLink: `/reports/municipality/${Monitor.Po}`, icon: 'auto_stories', roles: ['*'] },
          { name: 'Bibliotheekrapportage', routerLink: `/reports/library/${Monitor.Po}`, icon: 'auto_stories', roles: ['*'] },
          { name: 'Landelijke analyse', routerLink: `/reports/country/${Monitor.Po}`, icon: 'auto_stories', roles: ['*'] },
        ],
        hidden: this.reportDefaultFilterService.userPoi && !this.reportDefaultFilterService.userPoi.showPo,
      },
      {
        name: 'VO',
        icon: 'hail',
        children: [
          { name: 'Respons', routerLink: `/reports/response/${Monitor.Vmbo}`, icon: 'show_chart', roles: ['*'] },
          { name: 'Consulent', routerLink: `/reports/questionnaire/${QuestionnaireType.VoConsultant}`, icon: 'list_alt', roles: ['*'] },
          { name: 'Docent', routerLink: `/reports/questionnaire/${QuestionnaireType.VoTeacher}`, icon: 'list_alt', roles: ['*'] },
          { name: 'Leerling', routerLink: `/reports/questionnaire/${QuestionnaireType.VoStudent}`, icon: 'list_alt', roles: ['*'] },
          { name: 'Leescirkelrapportage', routerLink: `/reports/location/${Monitor.Vmbo}`, icon: 'auto_stories', roles: ['*'] },
          { name: 'Gemeenterapportage', routerLink: `/reports/municipality/${Monitor.Vmbo}`, icon: 'auto_stories', roles: ['*'] },
          { name: 'Bibliotheekrapportage', routerLink: `/reports/library/${Monitor.Vmbo}`, icon: 'auto_stories', roles: ['*'] },
          { name: 'Landelijke analyse', routerLink: `/reports/country/${Monitor.Vmbo}`, icon: 'auto_stories', roles: ['*'] },
        ],
        hidden: this.reportDefaultFilterService.userPoi && !this.reportDefaultFilterService.userPoi.showVo,
      },
      {
        name: `IKC`,
        icon: 'escalator_warning',
        hidden:
          this.reportDefaultFilterService.userPoi &&
          !this.reportDefaultFilterService.userPoi.showPo &&
          !this.reportDefaultFilterService.userPoi.showBookStart,
        children: [
          {
            name: 'Pedagogisch medewerker BSO',
            routerLink: `/reports/questionnaire/${QuestionnaireType.IkcPedagogicalEmployee}`,
            icon: 'list_alt',
            roles: ['*'],
          },
          {
            name: 'IKC-rapportage',
            routerLink: `/reports/ikcReport/${Monitor.Po}`,
            icon: 'auto_stories',
            roles: ['*'],
          },
        ],
      },
      {
        name: 'Beheer',
        routerLink: '/reports/beheer',
        icon: 'checklist',
        roles: [UserRoles.Poi],
        hidden: this.settingsService.fieldWorkIsClosed,
      },
      {
        name: 'Beheer',
        routerLink: '',
        icon: 'checklist',
        roles: [UserRoles.Administrator, UserRoles.LibraryCoordinator, UserRoles.ReadConsultant],
      },
      {
        name: 'Faq',
        routerLink: '/reports/faq',
        icon: 'help_outline',
        roles: [
          UserRoles.LibraryCoordinator,
          UserRoles.ReadConsultant,
          UserRoles.EducationManager,
          UserRoles.ReportCountryManager,
          UserRoles.Poi,
          UserRoles.Administrator,
        ],
      },
    ];
  }
}
