import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { AppInsightsService } from '@shared/util/infrastructure';

@Component({
  selector: 'pma-old-site-link',
  templateUrl: './old-site-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatCard, MatCardHeader, MatCardTitle, MatCardContent],
})
export class OldSiteLinkComponent {
  constructor() {
    AppInsightsService.trackTraceWarn(`oude link gebruikt: ${window.location.href}`);
  }
}
