import { EnvironmentProviders, Provider } from '@angular/core';
import { DateFnsAdapter, provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslationService } from '@shared/util/translations';
import { Locale } from 'date-fns';
import * as Locales from 'date-fns/locale';

export function getDateFnsLocale(localeId: string): Locale {
  const locale = localeId.split('-')[0];
  const region = localeId.split('-')[1] ?? '';
  const l = Object.entries(Locales).find((x) => x[0] === locale + region || x[0] === locale);
  return (l && l[1]) ?? Locales.enUS;
}

export function provideDataAdapter(localeId: string): Provider | EnvironmentProviders {
  return [
    { provide: MAT_DATE_LOCALE, useValue: getDateFnsLocale(localeId) },
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    provideDateFnsAdapter(),
  ];
}

export function provideDataAdapterQuestionnaire(): Provider | EnvironmentProviders {
  return [
    { provide: MAT_DATE_LOCALE, useFactory: () => getDateFnsLocale(TranslationService.localeISO) },
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    provideDateFnsAdapter(),
  ];
}
