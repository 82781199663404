import { Routes } from '@angular/router';
import { IsLoggedInGuard } from '@shared/feature/auth';
import { UserService } from '@shared/util/user';
import { LibraryService } from './core/library.service';
import { ReportAccessGuard } from './core/report-access.guard';
import { RoleFilterService } from './core/role-filter.service';
import { RoleRouter } from './core/role-router.guard';
import { SettingsGuard } from './core/settings.guard';
import { SettingsService } from './core/settings.service';
import { LibraryResolver } from './portal/library/library.resolver';
import { PortalComponent } from './portal/portal.component';
import { ImportPageComponent } from './pub/landing-pages/import-page/import-page.component';
import { ImportPageGuard } from './pub/landing-pages/import-page/import-page.guard';
import { OverviewPageComponent } from './pub/landing-pages/overview-page/overview-page.component';
import { OverviewPageGuard } from './pub/landing-pages/overview-page/overview-page.guard';
import { OldSiteLinkComponent } from './pub/old-site-link/old-site-link.component';
import { OverviewComponent } from './pub/overview/overview.component';
import { PageNotFoundComponent } from './pub/page-not-found/page-not-found.component';
import { QuestionnaireComponent } from './pub/questionnaire/questionnaire.component';
import { RegistrationMonitorCoordinatorComponent } from './pub/registration-monitor-coordinator/registration-monitor-coordinator.component';
import { RegistrationReportComponent } from './pub/registration-report/registration-report.component';
import { RegistrationReportService } from './pub/registration-report/registration-report.service';
import { SchoolAuthorizationComponent } from './pub/school-authorization/school-authorization.component';
import { PrintResponseResolver } from './reports/report/questionnaire/print-responses/print-response.resolver';
import { PrintResponsesComponent } from './reports/report/questionnaire/print-responses/print-responses';
import { PrintViewOpenQuestionsComponent } from './reports/report/questionnaire/print-view-open-questions/print-view-open-questions.component';
import { PrintViewOpenQuestionsResolver } from './reports/report/questionnaire/print-view-open-questions/print-view-open-questions.resolver';
import { PrintViewPreSelectionComponent } from './reports/report/questionnaire/print-view-pre-selection/print-view-pre-selection.component';
import { PrintViewComponent } from './reports/report/questionnaire/print-view/print-view.component';
import { ReportComponent } from './reports/report/report.component';

export const ROUTES: Routes = [
  { path: '', component: OverviewComponent, canActivate: [RoleRouter], providers: [UserService] },
  { path: 'overview', component: OverviewComponent },

  { path: 'PH/:id', component: OverviewPageComponent, canActivate: [OverviewPageGuard, SettingsGuard] },
  { path: 'BM/:id', component: OverviewPageComponent, canActivate: [OverviewPageGuard, SettingsGuard] },
  { path: 'BP/:id', component: OverviewPageComponent, canActivate: [OverviewPageGuard, SettingsGuard] },
  { path: 'BV/:id', component: OverviewPageComponent, canActivate: [OverviewPageGuard, SettingsGuard] },
  { path: 'PL/:id', component: OverviewPageComponent, canActivate: [OverviewPageGuard, SettingsGuard] },
  { path: 'VL/:id', component: OverviewPageComponent, canActivate: [OverviewPageGuard, SettingsGuard] },

  // PO/VO teachers/consultants: they may get a landingPage for importing questions
  { path: 'PC/:id', component: ImportPageComponent, canActivate: [ImportPageGuard, SettingsGuard] },
  { path: 'PK/:id', component: ImportPageComponent, canActivate: [ImportPageGuard, SettingsGuard] },
  { path: 'VC/:id', component: ImportPageComponent, canActivate: [ImportPageGuard, SettingsGuard] },
  { path: 'VK/:id', component: ImportPageComponent, canActivate: [ImportPageGuard, SettingsGuard] },

  // Demo and QR: Redirect to OverviewPage
  { path: 'PQ/:id', component: OverviewPageComponent, canActivate: [OverviewPageGuard, SettingsGuard] },
  { path: 'PD/:id', component: OverviewPageComponent, canActivate: [OverviewPageGuard, SettingsGuard] },
  { path: 'VQ/:id', component: OverviewPageComponent, canActivate: [OverviewPageGuard, SettingsGuard] },
  { path: 'VD/:id', component: OverviewPageComponent, canActivate: [OverviewPageGuard, SettingsGuard] },

  { path: 'BP', component: OverviewPageComponent, canActivate: [OverviewPageGuard, SettingsGuard] },
  { path: 'bo/Vragenlijst', component: OldSiteLinkComponent },
  { path: 'bo/Vragenlijst/:isil', component: OldSiteLinkComponent },
  { path: 'bo/Vragenlijst/:isil/:code', component: OldSiteLinkComponent },

  { path: 'RR/:id', component: RegistrationReportComponent, resolve: [RegistrationReportService] },
  { path: 'Authorize', component: SchoolAuthorizationComponent },
  { path: 'registratie/:id', component: RegistrationMonitorCoordinatorComponent, resolve: [LibraryService] },
  {
    path: 'auth',
    loadChildren: () => import('@shared/feature/auth').then((m) => m.ROUTES),
    data: { title: 'Monitor BoekStart en de Bibliotheek <i>op school</i>' },
  },
  {
    path: 'portal',
    component: PortalComponent,
    canActivate: [IsLoggedInGuard],
    resolve: [SettingsService],
    children: [
      { path: '', redirectTo: 'library', pathMatch: 'full' },
      { path: 'users', loadChildren: () => import('@portals/shared/feature/users').then((m) => m.ROUTES) },
      { path: 'settings', loadChildren: () => import('@portals/shared/feature/settings').then((m) => m.ROUTES) },
      { path: 'administration', loadChildren: () => import('./portal/administration/routes').then((m) => m.ROUTES) },
      { path: 'reportsettings', loadChildren: () => import('./portal/report-settings/routes').then((m) => m.ROUTES) },
      { path: 'spss', loadChildren: () => import('./portal/spss/routes').then((m) => m.ROUTES) },
      { path: 'reportgroups', loadChildren: () => import('./portal/report-groups/routes').then((m) => m.ROUTES) },
      {
        path: 'library',
        loadChildren: () => import('./portal/library/routes').then((m) => m.ROUTES),
        resolve: [LibraryResolver, SettingsService],
      },
    ],
  },
  { path: 'reports/print/:questionnaireType', component: PrintViewComponent },
  { path: 'reports/print-pre-selection/:monitorId', component: PrintViewPreSelectionComponent },
  {
    path: 'reports/print-open-questions/:questionnaireType',
    component: PrintViewOpenQuestionsComponent,
    resolve: {
      monitorOpenQuestions: PrintViewOpenQuestionsResolver,
    },
  },
  { path: 'reports/print-responses/:questionnaireType', component: PrintResponsesComponent, resolve: [PrintResponseResolver] },
  {
    path: 'reports',
    component: ReportComponent,
    canActivate: [ReportAccessGuard],
    loadChildren: () => import('./reports/routes').then((m) => m.ROUTES),
    resolve: [RoleFilterService, SettingsService],
  },

  // TODO: Maybe the links are going be simplified, without PQ, VK etc, then we have to do something here
  { path: ':startcode/:index', component: QuestionnaireComponent, canActivate: [SettingsGuard] },
  { path: ':startcode', component: QuestionnaireComponent, canActivate: [SettingsGuard] },
  { path: '**', component: PageNotFoundComponent },
];
