import { DOCUMENT, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChangeEmailModel, CurrentUserClient } from '@shared/data-access/common';
import { CloseOnEscapeDirective } from '@shared/ui/dialog';
import { FocusDirective, MxRecordValidatorDirective } from '@shared/ui/directives';
import { LoadingIndicatorService } from '@shared/util/infrastructure';
import { UserService } from '@shared/util/user';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'pma-my-profile',
  templateUrl: './my-profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CloseOnEscapeDirective,
    MatDialogTitle,
    FormsModule,
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatInput,
    FocusDirective,
    NgIf,
    MatError,
    MxRecordValidatorDirective,
    MatDialogActions,
    MatButton,
    MatDialogClose,
  ],
})
export class MyProfileComponent implements OnInit {
  userModel!: { userName: string; email: string };

  constructor(
    private userService: UserService,
    private currentUserClient: CurrentUserClient,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<MyProfileComponent>,
    @Inject(DOCUMENT) private document: Document,
    private indicator: LoadingIndicatorService,
  ) {}

  ngOnInit(): void {
    const user = this.userService.currentOrImpersonateUser;
    this.userModel = {
      email: user.email,
      userName: user.name,
    };
  }

  async onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    if (this.userModel.userName !== this.userService.currentOrImpersonateUser.name) {
      await firstValueFrom(this.currentUserClient.updateUserName(this.userModel.userName));
    }
    if (this.userModel.email !== this.userService.currentOrImpersonateUser.email) {
      await this.updateEmail();
    } else {
      this.closeDialog('Uw wijzigingen zijn opgeslagen.');
    }
  }

  private async updateEmail(): Promise<void> {
    const email = window.btoa(this.userModel.email);
    const loginUrl = `${this.document.baseURI}auth/change-email-confirmed/?email=${email}&userid={0}&c={1}`;
    const changeEmail: ChangeEmailModel = {
      newEmail: this.userModel.email,
      loginUrl,
    };
    this.indicator.showSpinner();
    await firstValueFrom(this.currentUserClient.changeEmail(changeEmail));
    const msg = 'Uw gegevens zijn opgeslagen. U ontvangt een e-mail op het opgegeven e-mailadres met een link om het e-mailadres te bevestigen.';
    this.closeDialog(msg);
  }

  private closeDialog(msg: string) {
    this.snackBar.open(msg);

    const user = this.userService.currentOrImpersonateUser;
    user.name = this.userModel.userName;
    user.email = this.userModel.email;
    this.dialogRef.close();
  }
}
