<div class="container">
  <div class="row">
    <div class="col">
      <img src="./assets/images/logo-dbos.svg" alt="Monitor de Bibliotheek op school" style="border-width: 0px" />
    </div>
  </div>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Rapportage {{ reportModel.locationName }} </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-tab-group *ngIf="service.reportModel.portal === monitor.BookStart">
        <mat-tab label="Groepen">
          <lsu-dynamic-table #lsdDynamicTable [columns]="columnDefinitionsBookStart" [data]="progressModels" maxHeight="calc(100vh - 257px)">
          </lsu-dynamic-table>
        </mat-tab>
      </mat-tab-group>
      <mat-tab-group *ngIf="service.reportModel.portal === monitor.Po || service.reportModel.portal === monitor.PoSpecial">
        <mat-tab label="Groepen">
          <lsu-dynamic-table #lsdDynamicTable [columns]="columnDefinitionsGroup" [data]="progressModels" maxHeight="calc(100vh - 257px)">
          </lsu-dynamic-table>
        </mat-tab>
        <mat-tab label="Leerkrachten">
          <lsu-dynamic-table #lsdDynamicTable [columns]="columnDefinitionsTeacher" [data]="progressModels" maxHeight="calc(100vh - 257px)">
          </lsu-dynamic-table>
        </mat-tab>
      </mat-tab-group>
      <mat-tab-group *ngIf="service.reportModel.portal === monitor.Vmbo">
        <mat-tab label="Klassen">
          <lsu-dynamic-table #lsdDynamicTable [columns]="columnDefinitionsGroup" [data]="service.voStudents" maxHeight="calc(100vh - 257px)">
          </lsu-dynamic-table>
        </mat-tab>
        <mat-tab label="Docenten">
          <div class="d-flex flex-column">
            <div class="p-2">Aantal docenten (nog niet begonnen): {{ service.voTeachersUnknown }}</div>
            <div class="p-2">Aantal docenten (begonnen): {{ service.voTeachersStarted }}</div>
            <div class="p-2">Aantal docenten (afgerond): {{ service.voTeachersCompleted }}</div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
