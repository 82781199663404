<div class="container-sm mt-8 d-flex align-items-center justify-content-center">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Importeren van eerdere antwoorden.</mat-card-title>
      <mat-card-subtitle>
        Je kunt hier antwoorden van een andere (afgeronde) vragenlijst importeren. Na het importeren wordt de vragenlijst geopend.
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      Jouw huidige {{ entityType }}: {{ entityName }}
      <br />
      <br />
      Na het kopiëren wordt de vragenlijst geopend met daarin de antwoorden van de vragenlijst die je hebt gekozen. Je kunt maar één keer kopiëren.
      <br />
      Je kunt dan alle antwoorden nalopen en eventueel nog aanpassingen doen.
      <br />
      <br />
      Wil je geen antwoorden overnemen van eerder ingevulde vragenlijsten, dan kun je op de link hieronder klikken.
      <br />
      <br />
      <a href="{{ questionnaireUrl }}">Vragenlijst openen</a>

      <lsu-dynamic-table [columns]="columnDefinitions" [data]="entities" (buttonClick)="actionButton($event)"> </lsu-dynamic-table>
    </mat-card-content>
  </mat-card>
</div>
