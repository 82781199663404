import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FollowerReportClient, Monitor, ProgressModel, ReportModel, ResponseStatus } from '../../core/webapi';

@Injectable({
  providedIn: 'root',
})
export class RegistrationReportService {
  reportModel!: ReportModel;
  voStudents!: ProgressModel[];
  voTeachersUnknown!: number;
  voTeachersStarted!: number;
  voTeachersCompleted!: number;
  constructor(private client: FollowerReportClient) {}

  resolve(snapshot: ActivatedRouteSnapshot): Observable<ReportModel> {
    const key = snapshot.paramMap.get('id') + '';
    return this.client.getSchoolLocation(key).pipe(
      tap((x) => {
        this.reportModel = x;
        if (this.reportModel.portal === Monitor.Vmbo) {
          this.voStudents = this.reportModel.progressModels.filter((x) => !!x.group);
          this.voTeachersUnknown = this.reportModel.progressModels.filter((x) => !x.group && x.surveyStatus === ResponseStatus.Unknown).length;
          this.voTeachersStarted = this.reportModel.progressModels.filter((x) => !x.group && x.surveyStatus === ResponseStatus.Started).length;
          this.voTeachersCompleted = this.reportModel.progressModels.filter((x) => !x.group && x.surveyStatus === ResponseStatus.Completed).length;
        }

        return x;
      }),
    );
  }
}
