import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SettingsService } from '../core/settings.service';

@Injectable({ providedIn: 'root' })
export class BreadcrumbsProvider {
  readonly breadCrumbs$ = new BehaviorSubject<string>('');

  constructor(
    router: Router,
    private settingsService: SettingsService,
  ) {
    router.events.pipe(filter((x): x is NavigationEnd => x instanceof NavigationEnd)).subscribe((x) => this.emitBreadCrumbs(x.urlAfterRedirects));
  }

  emitBreadCrumbs(url: string) {
    const result = this.getBreadCrumbs(url.split('/'));
    const res = result ? ' | ' + result : '';
    this.breadCrumbs$.next(`Monitor BoekStart en de Bibliotheek <i>op school</i>${res}  ${this.settingsService.measurementYear}`);
  }

  private getBreadCrumbs(parts: string[]) {
    if (parts.includes('bibliotheek')) {
      return 'Bibliotheek';
    }
    if (parts.includes('questionnaire')) {
      return 'Vragenlijst > BoekStart';
    }
    if (parts.includes('ikcs')) {
      return 'IKC';
    }
    if (parts.includes('faq')) {
      return 'Faq';
    }
    if (parts.includes('consultants')) {
      return 'Consulenten';
    }
    if (parts.includes('locationconsultants')) {
      return 'Locatiecoördinatoren';
    }

    const x = parts.includes('vo') ? 'Klas' : 'Groep';
    const part1 = parts.includes('bookstart') ? 'BoekStart' : parts.includes('po') ? 'PO' : parts.includes('vo') ? 'VO' : '';
    const part2 = parts.includes('registration') ? 'Registratie' : parts.includes('groups') ? x : 'Volgers';

    if (part1) {
      return `${part1} > ${part2}`;
    }
    return '';
  }
}
