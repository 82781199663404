import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { LOCALE_ID, enableProdMode, importProvidersFrom } from '@angular/core';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { DefaultUrlSerializer, UrlSerializer, UrlTree, provideRouter } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { authConfig } from '@shared/feature/auth';
import { tokenInterceptor } from '@shared/util/code';
import { provideDataAdapter } from '@shared/util/date-adapter';
import { infrastructureConfig } from '@shared/util/infrastructure';
import { materialConfig } from '@shared/util/material';
import { TranslocoHttpLoader, translationsConfig } from '@shared/util/translations';
import { QRCodeModule } from 'angularx-qrcode';
import { AppComponent } from './app/app.component';
import { ROUTES } from './app/routes';
import { RoleNl } from './app/shared/mdbos-user-roles';
import { environment } from './environments/environment';

const activeLanguage = 'nl-NL';
registerLocaleData(localeNl, activeLanguage);

class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  override parse(url: string): UrlTree {
    // questionnaire links should be case insensitive
    return super.parse(url.replace(/\/(ph|bm|bp|bv|pc|pk|pl|pd|vc|vk|vl|vd)\//gi, (x) => x.toUpperCase()));
  }
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        // Register the ServiceWorker as soon as the app is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      }),
      QRCodeModule,
    ),
    authConfig,
    infrastructureConfig(environment.apiUrl),
    translationsConfig(TranslocoHttpLoader.getTransLocoConfig(environment.production, activeLanguage), RoleNl),
    materialConfig(),
    provideDataAdapter(activeLanguage),
    provideHttpClient(withInterceptors([tokenInterceptor])),
    provideAnimations(),
    provideRouter(ROUTES),
    { provide: LOCALE_ID, useValue: activeLanguage },
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
  ],
}).catch((err) => console.error(err));
