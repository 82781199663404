import { Monitor } from '../../../core/webapi';

export interface CoverContent {
  title: string;
  logo: string;
}

export function getCoverContent(monitor: Monitor, document: Document): CoverContent {
  switch (monitor) {
    case Monitor.BookStart:
      return {
        logo: `${document.baseURI}assets/images/logo-boekstart.svg`,
        title: 'Monitor Boekstart in de kinderopvang',
      };
    case Monitor.Po:
      return {
        logo: `${document.baseURI}assets/images/logo-dbos.svg`,
        title: 'Monitor de Bibliotheek op school',
      };
    case Monitor.Vmbo:
      return {
        logo: `${document.baseURI}assets/images/logo-dbos.svg`,
        title: 'Monitor de Bibliotheek op school',
      };
    case Monitor.PoSpecial:
      return {
        logo: `${document.baseURI}assets/images/logo-dbos.svg`,
        title: 'Monitor de Bibliotheek op school',
      };
  }

  throw new Error(`Monitor type not found ${monitor}`);
}
