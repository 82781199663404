import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { CurrentOrImpersonateUser, UserService } from '@shared/util/user';
import { Observable, map, of } from 'rxjs';
import { UserRoles } from '../shared/mdbos-user-roles';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class RoleRouter {
  private readonly loggedInUser: CurrentOrImpersonateUser;

  constructor(
    private router: Router,
    private userService: UserService,
    private settingsService: SettingsService,
  ) {
    this.loggedInUser = userService.currentOrImpersonateUser;
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this.route();
  }

  public getUrlTree(role: string): UrlTree | boolean {
    switch (role) {
      case UserRoles.Administrator:
        return this.router.parseUrl(`/portal/administration`);
      case UserRoles.LibraryCoordinator:
        return this.router.parseUrl(`/portal/library/consultants`);
      case UserRoles.ReadConsultant:
        return this.router.parseUrl(`/portal/library/bibliotheek`);
      case UserRoles.EducationManager:
      case UserRoles.ReportCountryManager:
        return this.router.parseUrl(`/reports`);
      case UserRoles.Poi:
        return this.settingsService.fieldWorkIsClosed ? this.router.parseUrl(`/reports`) : this.router.parseUrl(`/reports/beheer`);
      case UserRoles.SchoolResultViewer:
      case UserRoles.LocationCoordinator:
        return this.router.parseUrl(`/reports`);
      default:
        throw new Error(`Onbekende rol: '${this.loggedInUser.role}'`);
    }
  }

  private route(): Observable<boolean | UrlTree> {
    if (this.loggedInUser.isAuthenticated) {
      // wait till finish impersonate to get the correct role. tryImpersonateDone is only fired once at application start
      return this.userService.tryImpersonateDone$.pipe(map(() => this.getUrlTree(this.loggedInUser.role)));
    }
    return of(true);
  }
}
