import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { safeLocalStorage } from '@shared/util/code';
import { firstValueFrom } from 'rxjs';
import { CategoryIdFilter, LocationResponseModel, ReportClient } from '../../../../core/webapi';

@Injectable({
  providedIn: 'root',
})
export class PrintResponseResolver {
  responseData!: LocationResponseModel[];
  questionnaireType!: number;
  years!: number[];
  constructor(private reportClient: ReportClient) {}
  async resolve(route: ActivatedRouteSnapshot): Promise<void> {
    const questionnaireType = +route.params['questionnaireType'];
    const locationFilter: CategoryIdFilter[] = JSON.parse(safeLocalStorage.getItem('mdbos.report.responses')!).filter;
    this.responseData = await firstValueFrom(this.reportClient.getLocationResponseData(questionnaireType, [locationFilter]));
    this.years = this.responseData.map((x) => x.measurementYear).sort();
    this.questionnaireType = questionnaireType;
  }
}
