import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { translateProjectCode } from '../../../core/translations/project-code-nl';
import { UriService } from '../../../core/uri.service';
import { QuestionnaireProjectClient, QuestionnaireType, StartCodeClient, UpdateSampleClient } from '../../../core/webapi';

@Injectable({
  providedIn: 'root',
})
export class ImportPageGuard {
  constructor(
    private uris: UriService,
    private client: QuestionnaireProjectClient,
    private startCodeClient: StartCodeClient,
    private sampleClient: UpdateSampleClient,
  ) {}

  canActivate(snapshot: ActivatedRouteSnapshot): Observable<boolean> {
    const startCode: string = snapshot.params['id'];
    const questionnaireCode = snapshot.url[0].path;
    const type = translateProjectCode(questionnaireCode);
    const isConsultant = [QuestionnaireType.PoConsultant, QuestionnaireType.VoConsultant].includes(type);

    // Check if the user has completed questionnaire lists
    return (
      isConsultant
        ? this.startCodeClient.getCompletedQuestionnairesConsultant(startCode, type)
        : this.startCodeClient.getCompletedQuestionnairesTeacher(startCode, type)
    ).pipe(switchMap((x) => (x.length > 0 ? of(true) : this.navigate(type, startCode))));
  }

  navigate(type: QuestionnaireType, startCode: string) {
    return this.sampleClient.updateSample(startCode).pipe(
      switchMap(() => forkJoin([this.client.getProjectGuid(type), this.startCodeClient.isResponseCopied(startCode!)])),
      map(([x, y]) => {
        location.href = `${this.uris.urlResponse}${x}/login/${startCode}${y ? '/MA' : ''}`;
        return false;
      }),
    );
  }
}
