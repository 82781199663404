<lsu-report-paged [initialize]="isReady" [pagedjs]="false">
  <lsu-report-paged-actions [fileName]="fileName" [generatePdfFromHTML]="true"></lsu-report-paged-actions>
  <lsu-report-paged-loader></lsu-report-paged-loader>

  <lsu-report-paged-page>
    <div class="w-100 text-center">
      <div class="mat-headline-5 mb-7">Rapportage</div>
      <img [src]="coverContent.logo" width="200" class="mb-7" />
      <div class="mat-subtitle-1 mb-7">
        <strong>{{ coverContent.title }}</strong>
      </div>
      <div *ngIf="selections.locations.length" class="mat-body mb-7">Geselecteerde {{ selections.locationType }}</div>
      <div *ngIf="selections.locations.length" class="mb-7">
        <ng-container *ngIf="selections.locations.length">
          <div class="mat-subtitle-1 mb-0" *ngFor="let location of selections.locations">
            <strong>
              {{ location }}
            </strong>
          </div>
        </ng-container>
      </div>
    </div>
  </lsu-report-paged-page>

  <lsu-report-paged-page *ngFor="let question of selectedQuestions; let i = index" class="d-block position-relative">
    <div class="d-flex justify-content-center mt-2 mb-2" *ngIf="question.title">
      <span [innerHTML]="question.title"></span>
    </div>
    <ng-container *ngIf="!question.defaultDataFound">
      <div class="d-flex justify-content-center mt-2 mb-2">
        <h2 [innerHTML]="question.description"></h2>
      </div>
      <div class="d-flex justify-content-center mb-2">Er zijn geen resultaten gevonden.</div>
      <span class="d-none">{{ rendered() }}</span>
    </ng-container>
    <pma-report-chart-column
      (rendered)="rendered()"
      class="chart"
      [print]="true"
      [reflow]="reflow"
      *ngIf="!question.isMultiResponse && question.defaultDataFound && highchartData[question.order]"
      [data]="highchartData[question.order]"
    ></pma-report-chart-column>
    <pma-report-chart-bar
      class="chart"
      (rendered)="rendered()"
      [print]="true"
      [reflow]="reflow"
      *ngIf="question.isMultiResponse && question.defaultDataFound && highchartData[question.order]"
      [data]="highchartData[question.order]"
    ></pma-report-chart-bar>
    <div class="w-100 text-end page-number">
      <span class="text">Pagina {{ i + 1 }}</span>
    </div>
  </lsu-report-paged-page>
</lsu-report-paged>
