<lsu-report-paged [initialize]="true">
  <lsu-report-paged-actions [fileName]="fileName" [generatePdfFromHTML]="true"></lsu-report-paged-actions>
  <lsu-report-paged-loader></lsu-report-paged-loader>
  <lsu-report-paged-page>
    <div class="w-100 text-center">
      <div class="mat-headline-5 mb-7">Respons</div>
      <img [src]="coverContent.logo" width="200" class="mb-7" />
      <div class="mat-subtitle-1 mb-7"><strong>{{coverContent.title}}</strong></div>
      <div *ngIf="selectedLocations.locations.length" class="mb-7">
        <ng-container *ngIf="selectedLocations.locations.length <= 5">
          <div class="mat-subtitle-1 mb-0" *ngFor="let location of selectedLocations.locations">
            <strong> {{location}} </strong>
          </div>
        </ng-container>
        <ng-container *ngIf="selectedLocations.locations.length > 5">
          <div class="mat-subtitle-1 mb-0">
            <strong>Voor deze selectie zijn meer dan 5 {{selectedLocations.locationType}} meegenomen</strong>
          </div>
        </ng-container>
      </div>
    </div>
  </lsu-report-paged-page>

  <lsu-report-paged-page>
    <lsu-dynamic-table [columns]="columnDefinitionsResponse" [data]="responseData"></lsu-dynamic-table>
  </lsu-report-paged-page>
</lsu-report-paged>
