import { AsyncPipe, DOCUMENT, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PagedActionsComponent, PagedComponent, PagedLoaderComponent, PagedPageComponent } from '@shared/ui/report';
import { safeLocalStorage } from '@shared/util/code';
import { UserError } from '@shared/util/infrastructure';
import { SelectedLocations } from '../../../../shared/report-label-retriever';
import { ChartDisplayQuestion } from '../../../../shared/report-types';
import { ChartBarComponent } from '../chart-bar/chart-bar.component';
import { ChartColumnComponent } from '../chart-column/chart-column.component';
import { SomeHighchartsOptions } from '../highchart-data.service';
import { CoverContent, getCoverContent } from '../print';

@Component({
  templateUrl: './print-view-pre-selection.component.html',
  styleUrls: ['./print-view-pre-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PagedComponent,
    AsyncPipe,
    PagedActionsComponent,
    PagedLoaderComponent,
    PagedPageComponent,
    NgIf,
    NgFor,
    ChartColumnComponent,
    ChartBarComponent,
  ],
})
export class PrintViewPreSelectionComponent {
  readonly selectedQuestions: ChartDisplayQuestion[];
  readonly fileName: string;
  readonly selections: SelectedLocations;
  readonly coverContent: CoverContent;
  highchartData: Record<number, SomeHighchartsOptions> = {};
  reflow = false;
  renderCount = 0;
  isReady = false;

  constructor(
    @Inject(DOCUMENT) document: Document,
    private changeDetectorRef: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
  ) {
    const monitorId = +activatedRoute.snapshot.params['monitorId'];
    const highchartData = safeLocalStorage.getItem('mdbos.report.preselection.highchartData');
    const selectedQuestions = safeLocalStorage.getItem('mdbos.report.preselection.selectedQuestions');
    const locationFilterLabel = safeLocalStorage.getItem('mdbos.report.locationFilterLabel');
    if (!highchartData || !selectedQuestions || !locationFilterLabel) {
      throw new UserError('Sluit deze pagina en probeer het opnieuw!', 'Er zijn geen grafiekresultaten gevonden in het geheugen.');
    }
    this.selections = JSON.parse(safeLocalStorage.getItem('mdbos.report.preselection.selections') ?? '{}');

    const highchartDataParsed: Record<number, SomeHighchartsOptions | null> = JSON.parse(highchartData);
    this.setHighChartData(highchartDataParsed);
    this.selectedQuestions = JSON.parse(selectedQuestions);
    const fileName = locationFilterLabel.replace(/(^"|"$)/g, '');
    this.fileName = `${fileName}${!fileName.endsWith(')') ? '-' : ''}${new Date().getFullYear()}${new Date().getMonth() + 1}${new Date().getDate()}`;

    this.coverContent = getCoverContent(monitorId, document);
  }

  async rendered() {
    this.renderCount++;
    if (this.renderCount === Object.values(this.highchartData).filter((x) => Object.keys(x).length > 0).length) {
      // make sure all charts are rendered before changing the html with pagedJs
      this.isReady = true;
      this.changeDetectorRef.detectChanges();
      // then reflow the charts to fit their new containers
      this.reflow = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  private setHighChartData(highchartData: Record<number, SomeHighchartsOptions | null>): void {
    Object.values(highchartData).forEach((x, index) => {
      this.highchartData[index] = x ? x : ({} as SomeHighchartsOptions);
    });
  }
}
