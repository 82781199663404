import { QuestionnaireType } from '../webapi';

export function translateProjectCode(code: string) {
  switch (code) {
    case 'PH':
      return QuestionnaireType.IkcPedagogicalEmployee;
    case 'BM':
      return QuestionnaireType.BookStartLibraryEmployee;
    case 'BP':
      return QuestionnaireType.BookStartPedagogicalEmployee;
    case 'BV':
      return QuestionnaireType.BookStartConsultant;
    case 'PC':
      return QuestionnaireType.PoConsultant;
    case 'PK':
      return QuestionnaireType.PoTeacher;
    case 'PL':
    case 'PD':
    case 'PQ':
      return QuestionnaireType.PoStudent;
    case 'VC':
      return QuestionnaireType.VoConsultant;
    case 'VK':
      return QuestionnaireType.VoTeacher;
    case 'VL':
    case 'VD':
    case 'VQ':
      return QuestionnaireType.VoStudent;
    default:
      throw Error(`Unknown code ${code}`);
  }
}
