import { DOCUMENT, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PagedActionsComponent, PagedComponent, PagedLoaderComponent, PagedPageComponent } from '@shared/ui/report';
import { safeLocalStorage } from '@shared/util/code';
import { getMonitorType } from '../../../../core/translations/get-questionnaire-types';
import { QuestionnaireTypeNl } from '../../../../core/translations/questionnairetype-nl';
import { Monitor, MonitorOpenQuestions, QuestionnaireType } from '../../../../core/webapi';

interface CoverContent {
  title: string;
  logo: string;
}

@Component({
  selector: 'pma-report-print-view-open-questions',
  templateUrl: './print-view-open-questions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PagedComponent, PagedActionsComponent, PagedLoaderComponent, PagedPageComponent, NgIf, NgFor],
})
export class PrintViewOpenQuestionsComponent {
  readonly baseURI: string;
  readonly questionnaireTypeNl = QuestionnaireTypeNl;
  readonly coverContent: CoverContent;
  readonly monitorWithoutOpenQuestions: MonitorOpenQuestions[];
  readonly monitorWithOpenQuestions: MonitorOpenQuestions[];
  readonly locations: string[];
  readonly fileName: string;
  isReady = false;
  renderCount!: number;
  renderedParts = 0;
  public year: number;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.baseURI = this.document.baseURI;
    const locations: string[] = JSON.parse(safeLocalStorage.getItem('mdbos.report.locationFilter') ?? '{}').locations;
    this.locations = locations;
    this.fileName = `${locations[0]}-${new Date().getFullYear()}-open-vragen`;

    const questionnaireType = +activatedRoute.snapshot.params['questionnaireType'];
    this.coverContent = this.getCoverContent(questionnaireType);

    const monitorOpenQuestions: MonitorOpenQuestions[] = activatedRoute.snapshot.data['monitorOpenQuestions'];
    this.monitorWithOpenQuestions = monitorOpenQuestions.filter((monitor) => monitor.openQuestions.length);
    this.monitorWithoutOpenQuestions = monitorOpenQuestions.filter((monitor) => !monitor.openQuestions.length);

    this.year = monitorOpenQuestions[0].measurementYear;

    this.renderCount = !this.monitorWithOpenQuestions.length || !this.monitorWithoutOpenQuestions.length ? 1 : 2;
  }

  checkReadyForPreview() {
    // we have to make sure all html is rendered before pagedJs will execute, therefor we need to know how many parts of html should be rendered
    this.renderedParts++;
    if (this.renderedParts === this.renderCount) {
      this.isReady = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  private getCoverContent(questionnaireType: QuestionnaireType): CoverContent {
    const monitor = getMonitorType(questionnaireType);
    switch (monitor) {
      case Monitor.BookStart:
        return {
          logo: `${this.document.baseURI}assets/images/logo-boekstart.svg`,
          title: 'Monitor Boekstart in de kinderopvang',
        };
      case Monitor.Po:
        return {
          logo: `${this.document.baseURI}assets/images/logo-dbos.svg`,
          title: 'Monitor de Bibliotheek op school',
        };
      case Monitor.Vmbo:
        return {
          logo: `${this.document.baseURI}assets/images/logo-dbos.svg`,
          title: 'Monitor de Bibliotheek op school',
        };
      case Monitor.PoSpecial:
        return {
          logo: `${this.document.baseURI}assets/images/logo-dbos.svg`,
          title: 'Monitor de Bibliotheek op school',
        };
    }

    throw new Error(`Questionnaire type not found ${questionnaireType}`);
  }
}
