<lsuCloseOnEscape></lsuCloseOnEscape>
<h1 mat-dialog-title>Wijzig uw profiel</h1>
<form #form="ngForm" (ngSubmit)="onSubmit(form)">
  <mat-dialog-content>
    <div class="row mb-2">
      <div class="col">
        Vul hier uw naam en e-mailadres in. Bij het wijzigen van het e-mailadres krijgt u een mail ter bevestiging. Na de bevestiging zal de wijziging
        worden doorgevoerd.
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Uw naam</mat-label>
          <input #userName="ngModel" [(ngModel)]="userModel.userName" matInput name="userName" required lsuDirectivesFocus />
          <mat-error *ngIf="userName.invalid"> Het naam veld is verplicht </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Uw e-mailadres</mat-label>
          <input #email="ngModel" [(ngModel)]="userModel.email" matInput mxRecordValidator name="email" required />
          <mat-error *ngIf="email.hasError('mxRecordValidator')">{{ email.getError("mxRecordValidator") }}</mat-error>
          <mat-error *ngIf="email.hasError('required')">Het veld 'E-mailadres' is verplicht.</mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button class="mr-2" mat-raised-button color="primary">Opslaan</button>
    <button mat-stroked-button mat-dialog-close>Annuleren</button>
  </mat-dialog-actions>
</form>
